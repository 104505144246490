<template>
  <q-form ref="editForm">
    <c-card title="기본정보" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn 
            v-show="!disabled && isOld && editable"
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="cto"
            mappingType="PUT"
            label="완료" 
            icon="check"
            @beforeAction="completeCto"
            @btnCallback="completeCallback" />
          <c-btn 
            v-show="!disabled && isOld && editable" 
            label="삭제" 
            icon="delete_forever" 
            @btnClicked="remove" />
          <c-btn
            v-show="!disabled && editable"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="cto"
            :mappingType="saveType"
            label="저장"
            icon="save"
            @beforeAction="saveCto"
            @btnCallback="saveCallback" 
          />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-text
            required
            :readonly="true"
            :editable="editable"
            :afterIcon="editable&&!disabled ? [
              { name: 'search', click: true, callbackName: 'searchSop', color: 'teal' },
              { name: 'close', click: true, callbackName: 'removeSop', color: 'red' }
            ] : null"
            label="관찰작업 / 공정"
            name="sopProcessName"
            v-model="sopProcessName"
            @searchSop="openSop"
            @removeSop="removeSop">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            :editable="editable"
            :disabled="disabled"
            type="date"
            label="관찰일"
            name="ctoDate"
            v-model="cto.ctoDate"
          />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-datepicker
            required
            :editable="editable"
            :disabled="disabled"
            type="time"
            :minuteStep="10"
            label="소요시간"
            name="ctoTime"
            v-model="cto.ctoTime"
          />
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            required
            :editable="editable"
            :disabled="disabled"
            suffix="명"
            type="number"
            label="관찰인원"
            name="observeCount"
            v-model="cto.observeCount">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-multi-field
            required
            :editable="editable"
            :disabled="disabled"
            :data="cto"
            label="관찰자"
            type="dept_user"
            name="observeUserId"
            v-model="cto.observeUserId">
          </c-multi-field>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-text
            :editable="editable"
            :disabled="disabled"
            label="작업구역"
            name="workArea"
            v-model="cto.workArea">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2">
          <c-plant 
            required
            :disabled="disabled || isOld"
            :editable="editable" 
            type="edit" 
            name="plantCd" 
            v-model="cto.plantCd" />
        </div>
      </template>
    </c-card>
    <c-card title="총평" class="cardClassDetailForm">
      <template slot="card-detail"><div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <c-multi-select
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            codeGroupCd="CTO_WORK_CHARACTER_CD"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="workCharacteristics"
            label="작업특성"
            v-model="cto.workCharacteristics">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <c-multi-select
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            codeGroupCd="CTO_HUMAN_FACTOR_CD"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="humanFactors"
            label="인적요소"
            v-model="cto.humanFactors">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4">
          <c-multi-select
            :editable="editable"
            :disabled="disabled"
            :isArray="false"
            codeGroupCd="CTO_CORE_ACTION_ITEMS_CD"
            itemText="codeName"
            itemValue="code"
            maxValues="3"
            name="coreActionItems"
            label="핵심행동항목"
            v-model="cto.coreActionItems">
          </c-multi-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="우선사항"
            :rows="2"
            name="priorityContents"
            v-model="cto.priorityContents">
          </c-textarea>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <c-textarea
            :editable="editable"
            :disabled="disabled"
            label="개선사항"
            :rows="2"
            name="improveContents"
            v-model="cto.improveContents"
          />
        </div>
      </template>
    </c-card>
    <c-table
      ref="activityTable"
      title="핵심행동 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="cto.activityModels"
      :filtering="false"
      :columnSetting="false"
      :usePaging="false"
      noDataLabel="핵심행동을 추가하세요."
      :hideBottom="true"
      :editable="editable&&!disabled"
      rowKey="sopCtoActivityId"
      selection="multiple"
    >
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn 
            v-if="editable && !disabled" 
            :showLoading="false" 
            label="추가" 
            icon="add" 
            @btnClicked="addActivity" />
          <c-btn 
            v-if="editable && !disabled && cto.activityModels.length > 0" 
            :showLoading="false"  
            label="제외" 
            icon="remove" 
            @btnClicked="removeActivity" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar'
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'ctoInfo',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopCtoId: '',
      }),
    },
    cto: {
      type: Object,
      default: () => ({
        sopCtoId: '',
        plantCd: '',
        ctoDate: '',
        ctoTime: '',
        observeUserId: '',
        observeCount: '',
        mdmSopId: '',
        workArea: '',
        ctoCompleteFlag: 'N',
        improveContents: '',
        priorityContents: '',
        managerUserId: '',
        sopName: '',
        processName: '',
        regUserId: '',
        chgUserId: '',
        workCharacteristics: '',
        humanFactors: '',
        coreActionItems: '',
        activityModels: [],
        ctoChecklistModels: [],
        ctoImproveModels: [],
      }),
    },
    isOld: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'mainActivity',
            field: 'mainActivity',
            label: '핵심행동',
            align: 'left',
            style: 'width:50%',
            type: 'text',
            sortable: false,
          },
          {
            name: 'goodBadCd',
            field: 'goodBadCd',
            label: '양호/불량/해당없음',
            type: 'select',
            setHeader: true,
            style: 'width:20%',
            align: 'center',
            codeGroupCd: 'CTO_GOOD_BAD_CD',
            sortable: false,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            type: 'textarea',
            style: 'width:30%',
            align: 'center',
            sortable: false
          },
        ],
        height: '300px'
      },
      editable: true,
      isSave: false,
      isComplete: false,
      saveUrl: '',
      completeUrl: '',
      deleteUrl: '',
      saveType: 'POST',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    sopProcessName() {
      let name = '';
      if (this.cto.mdmSopId) {
        name = `${this.cto.sopName} / ${this.cto.processName}`
      }
      return name;
    }
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.sop.cto.insert.url;
      this.deleteUrl = transactionConfig.sop.cto.delete.url;
      this.completeUrl = transactionConfig.sop.cto.complete.url;
      // code setting
      // list setting
    },
    addActivity() {
      this.cto.activityModels.splice(0, 0, {
        sopCtoId: this.popupParam.sopCtoId,
        sopCtoActivityId: uid(),
        goodBadCd: 'CGB0000003',
        mainActivity: '',
        remarks: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: 'C'
      })
    },
    removeActivity() {
      let selectData = this.$refs['activityTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          if (!this.cto.deleteActivityModels) this.cto.deleteActivityModels = [];
          if (this.$_.findIndex(this.cto.deleteActivityModels, { sopCtoActivityId: item.sopCtoActivityId }) === -1
            && item.editFlag !== 'C') {
              this.cto.deleteActivityModels.push(item)
          }
          this.cto.activityModels = this.$_.reject(this.cto.activityModels, item)
        })
      }
    },
    openSop() {
      this.popupOptions.title = 'SOP 검색'; // SOP 검색
      this.popupOptions.param = {
        type: 'single',
        searchType: '1', //1: 작업조회, 2:작업단계, 3:작업단계별 유해위험요인, 4:작업단계별 유해위험요인별 원인/결과
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/sopPop.vue'}`);
      this.popupOptions.width = '70%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeSopPopup;
    },
    closeSopPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.cto.mdmSopId = data[0].mdmSopId;
        this.cto.sopName = data[0].sopName;
        this.cto.processName = data[0].processName;
      }
    },
    removeSop() {
      this.cto.mdmSopId = '';
      this.cto.sopName = '';
      this.cto.processName = '';
    },
    saveCto() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.cto.update.url;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.cto.insert.url;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.cto.regUserId = this.$store.getters.user.userId
              this.cto.chgUserId = this.$store.getters.user.userId
              
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopCtoId', result.data)
      this.$emit('getDetail')
    },
    completeCto() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.cto.regUserId = this.$store.getters.user.userId
              this.cto.chgUserId = this.$store.getters.user.userId
              
              this.isComplete = !this.isComplete;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$emit('getDetail')
    },
    /* eslint-disable no-unused-vars */ 
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopCtoId);
          this.$http.type = 'DELETE';
          this.$http.request((_result) => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', 'REMOVE');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
  }
};
</script>
